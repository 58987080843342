import React from 'react';
import { Cell, DropdownList, Form, Panel, TextField, VMContext, withTheme } from 'dotnetify-elements';

const BasicInfoForm = () => (
    <Panel horizontal noGap>
        <Panel horizontal>
            <Cell header="Connection Info" flex>
                <VMContext vm="ConnectionForm">
                    <Form id="Connection">
                        <Panel childProps={{ horizontal: true }}>
                            <TextField id="Id" />
                            <TextField id="Name" />
                            <TextField id="ClientId" />
                            <TextField id="BapiKey" />
                        </Panel>
                    </Form>
                </VMContext>
            </Cell>
        </Panel>
    </Panel>
);

export default withTheme(BasicInfoForm);
