import React from 'react';
import { Alert, Button, Markdown, Panel, RadioToggle, TabItem, withTheme } from 'dotnetify-elements';
import { TabsArticle, RenderCustomize, RenderExample } from '../../components';

const SupplierMethod = props => (
    <TabsArticle vm="SupplierMethod" id="Overview">
        <TabItem label="Overview" itemKey="Overview">
            <Markdown id="Overview" />
        </TabItem>
        <TabItem label="GET" itemKey="GET">
            <Markdown id="GET" />
        </TabItem>
        <TabItem label="POST" itemKey="POST">
            <Markdown id="POST" />
        </TabItem>
    </TabsArticle>
);

export default withTheme(SupplierMethod);
