import React from 'react';
import { Element, Label, Markdown, Panel, TabItem, withTheme } from 'dotnetify-elements';
import { TabsArticle, RenderCustomize, RenderExample } from '../../components';

const AboutUs = (props) => (
	<TabsArticle vm="DisplayLabel" id="Overview">
		<TabItem label="Overview" itemKey="Overview">
			<Markdown id="Overview">
				<LabelExample />
			</Markdown>
		</TabItem>
        <TabItem label="AboutUs" itemKey="AboutUs">
            <Markdown id="AboutUs" />
		</TabItem>
		<TabItem label="Customize">
			<LabelCustomize />
		</TabItem>
	</TabsArticle>
);

export default withTheme(AboutUs);
