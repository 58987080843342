import React from 'react';
import { Element, Label, Markdown, Panel, TabItem, withTheme } from 'dotnetify-elements';
import { TabsArticle, RenderCustomize, RenderExample } from '../../components';
import { DataGrid, GridColumn, VMContext, LineChart, Frame, Card } from '../../../../src/dotnetify-elements';

const Statistics = (props) => (
	<VMContext vm="PulseVM">
		<Frame css="max-width: calc(100% - 3rem)">
			<Panel flex="0 0 auto" horizontal>
				
				<Panel horizontal flex="1">
					<Card>
						<LineChart id="TotalCpuTrend" streaming="true" tooltip={true} />
					</Card><Card>
						<LineChart id="GCTotalMemoryTrend" streaming="true" tooltip={true} />
					</Card>
				</Panel>
			</Panel>
			<DataGrid id="Logs">
				<GridColumn colKey="FormattedTime" width="13%" />
				<GridColumn colKey="Level" width="100px" />
			</DataGrid>
		</Frame>
	</VMContext>
);

export default withTheme(Statistics);
