import React from "react";
import { Label, Panel } from "dotnetify-elements";
import SwaggerLogo from "../assets/swagger_icon.png";
import styled from "styled-components";
const LogoCss = styled.img`
    width: 50px;
    height: 50px;
`;

export default (props) => {
    return (<Panel
        padding="0rem 1rem .5rem 1rem; &:hover { background: #efefef; }"
        height="3rem"
        horizontal
        middle
    >
        <a href="">
            <Label>
                <img src={SwaggerLogo} width="40" height="40" />
            </Label>
        </a>
        <d-panel>
            <a href="https://vismaapi.garnesdemo.no/swagger">
                <Label>Swagger</Label>
            </a>
        </d-panel>
    </Panel>);
}