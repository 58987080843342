import React from 'react';
import { Main, Header, Footer, Nav, NavDrawerButton, NavMenu, NavMenuTarget, Panel, Section, VMContext } from 'dotnetify-elements';
import AppLogo, { ThemeToggle, LicenseNotice } from '../components/Logo';
import MenuLinks from '../components/MenuLinks';
import lightTheme from 'dotnetify-elements/theme-light';
import darkTheme from 'dotnetify-elements/theme-dark';
import { themeToggleEvent } from './layout/demo-helper';
import { auth, getAuthHeaders } from '../auth';
import { Login } from '../login';
import SwaggerLink from '../components/SwaggerLink';

const navCss = `
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 20px;
  }
`;


class App extends React.Component {
    handleAuthenticated = _ => this.setState({ authenticated: true });
    constructor(props) {
        super(props);

        this.state = {
            authenticated: auth.hasAccessToken(),
            theme: lightTheme
        };
        //Subscripe for color changing
        //Also an example for subscriping to events within the app
        themeToggleEvent.subscribe(arg => {
            this.setState({ theme: arg || this.state.theme.name === 'light' ? darkTheme : lightTheme });
        });
    }

    render() {
        const { theme } = this.state;
        return !this.state.authenticated ? <Login onAuthenticated={this.handleAuthenticated} /> : <MainApp theme={theme} />;
    }
}



const MainApp = props => (<VMContext vm="VismaWebApp" options={getAuthHeaders()}>
    <Main theme={props.theme}>
        <Header>
            <NavDrawerButton show css="margin-left: 1rem" />
            <AppLogo />
            <MenuLinks active="elements" />
            <Panel horizontal center middle right padding="1rem">
                <ThemeToggle name={props.theme.name} onClick={_ => themeToggleEvent.emit()} />
            </Panel>
        </Header>
        <Nav css={navCss}>
            <Panel noGap>
                <MenuLinks nav={true} active="elements" />
                <NavMenu id="NavMenu" />
                <SwaggerLink />
            </Panel>
        </Nav>
        <Section>
            <NavMenuTarget />
        </Section>
        <Footer>
            <LicenseNotice>
                © 2019 Garnes Data. Licensed under the <a href="http://www.apache.org/licenses/LICENSE-2.0">Apache license version 2.0</a>
            </LicenseNotice>
        </Footer>
    </Main>
</VMContext>);

export default App;
