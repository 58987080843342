import React from 'react';
import BasicInfoForm from './BasicInfoForm';
import NewConnectionsDialog from './NewConnectionsDialog';
import { Card, Button, DataGrid, Form, Frame, Panel, Tab, TabItem, VMContext, withTheme, Label } from 'dotnetify-elements';
import { auth, getAuthHeaders } from '../../../auth';


class VismaSetup extends React.Component {
    state = { editable: false, edit: false, openDialog: false, openDelete: false };

    handleSelect = value => this.setState({ editable: value ? true : false });
    toggleEdit = _ => this.setState({ edit: !this.state.edit });
    toggleDialog = _ => this.setState({ openDialog: !this.state.openDialog });
    toggleDelete = () => this.toggleEdit();

    render() {
        const RemoveLabel = <Label icon="material-icons highlight_off">Remove</Label>;
        const { editable, edit, openDialog, openDelete } = this.state;
        const canEdit = editable && !edit;
        //options={getAuthHeaders()}
        return (
            <VMContext vm="VismaSetup" options={getAuthHeaders()}>
                <Frame css="max-width: calc(100% - 3rem)">
                    <DataGrid id="Connections" onSelect={this.handleSelect} enable={!edit} />
                    <Form plainText={!edit}>
                        <Panel>
                            {/* Toolbar */}
                            <Panel horizontal>
                                <Panel horizontal>
                                    <Button label="Edit" enable={canEdit} onClick={this.toggleEdit} />
                                    <Button label="Update" id="Submit" submit show={edit} onClick={this.toggleEdit} />
                                    <Button label={RemoveLabel} id="Delete" />
                                    <Button label="Cancel" cancel secondary show={edit} onClick={this.toggleEdit} />
                                </Panel>
                                <Panel horizontal right>
                                    <Button label="New Connection" onClick={this.toggleDialog} enable={!edit} />
                                </Panel>
                            </Panel>
                            <BasicInfoForm />
                        </Panel>
                    </Form>
                </Frame>
                <NewConnectionsDialog open={openDialog} onClose={this.toggleDialog} />
            </VMContext>);
    };
};

export default withTheme(VismaSetup);
