import React from 'react';
import { Button, DropdownList, Form, Modal, NumberField, Panel, Tab, TabItem, TextField, VMContext } from 'dotnetify-elements';

export default class NewCustomerDialog extends React.Component {
    state = { activeTab: 'Person' };

    handleClose = _ => this.props.onClose();
    handleSubmitError = data => this.setState({ activeTab: data.failedForms[0].formId });
    handleActivate = tab => this.setState({ activeTab: tab });

    render() {
        const { open } = this.props;
        const { activeTab } = this.state;
        return (
            <VMContext vm="NewConnectionForm">
                <Modal header="New Connection" open={open} large onSubmitError={this.handleSubmitError}>
                    <VMContext vm="ConnectionForm">
                        <Form id="Connection">
                            <Panel>
                                <TextField id="Name" />
                                <TextField id="ClientId" />
                                <TextField id="BapiKey" />
                            </Panel>
                        </Form>
                    </VMContext>
                    <footer>
                        <Panel horizontal right>
                            <Button label="Cancel" cancel secondary onClick={this.handleClose} />
                            <Button label="Submit" id="Submit" submit onClick={this.handleClose} />
                        </Panel>
                    </footer>
                </Modal>
            </VMContext>
        );
    }
}
