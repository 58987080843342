import React from 'react';
import { Markdown, TabItem, withTheme } from 'dotnetify-elements';
import { TabsArticle } from '../../components';

const CustomerMethod = props => (
    <TabsArticle vm="CustomerMethod" id="Overview">
        <TabItem label="Overview" itemKey="Overview">
            <Markdown id="Overview" />
        </TabItem>
        <TabItem label="GET" itemKey="GET">
            <Markdown id="GET" />
        </TabItem>
    </TabsArticle>
);
export default withTheme(CustomerMethod);
