import Home from './Home';

import VismaSetup from './Configuration/VismaSetup/VismaSetup';
import Security from './Configuration/Security';
import AccessTokens from './Configuration/AccessTokens';
import CustomerMethod from './APIReference/Customer';
import EmployeeMethod from './APIReference/Employee';
import InstanceMethod from './APIReference/Instance';
import SupplierMethod from './APIReference/Supplier';
import AboutUs from './About/AboutUs';
import Statistics from './Statistics/Statistics';

export {
  Home,
  VismaSetup,
  Security,
  AccessTokens,
  InstanceMethod,
  EmployeeMethod,
  CustomerMethod,
  SupplierMethod,
  AboutUs,
  Statistics
};
