import React from 'react';
import { Element, Label, Markdown, Panel, TabItem, withTheme } from 'dotnetify-elements';
import { TabsArticle, RenderCustomize, RenderExample } from '../../components';

const DisplayLabel = (props) => (
	<TabsArticle vm="DisplayLabel" id="Overview">
		<TabItem label="Overview" key="Overview">
			<Markdown id="Overview">
				<LabelExample />
			</Markdown>
		</TabItem>
		<TabItem label="API" key="API">
			<Markdown id="API" />
		</TabItem>
		<TabItem label="Customize">
			<LabelCustomize />
		</TabItem>
	</TabsArticle>
);

export default withTheme(DisplayLabel);
