import React from 'react';
import { Markdown, TabItem, withTheme } from 'dotnetify-elements';
import { TabsArticle } from '../../components';

const EmployeeMethod = props => (
    <TabsArticle vm="EmployeeMethod" id="Overview">
        <TabItem label="Overview" itemKey="Description">
            <Markdown id="Overview" />
        </TabItem>
        <TabItem label="GET" itemKey="GET">
            <Markdown id="GET" />
        </TabItem>
    </TabsArticle>
);
export default withTheme(EmployeeMethod);
