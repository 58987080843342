import React from 'react';
import { Markdown, Panel, TabItem, VMContext, withTheme } from 'dotnetify-elements';
import { TabsArticle, RenderCustomize, RenderExample } from '../../components';

const DisplayMarkdown = props => (
   <TabsArticle vm="DisplayMarkdown" id="Overview">
      <TabItem label="Overview" key="Overview">
         <Markdown id="Overview">
            <MarkdownExample />
            <InsetExample />
         </Markdown>
      </TabItem>
      <TabItem label="API" key="API">
         <Markdown id="API" />
      </TabItem>
      <TabItem label="Customize">
         <MarkdownCustomize />
      </TabItem>
   </TabsArticle>
);


export default withTheme(DisplayMarkdown);
